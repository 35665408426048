.portfolio__projectSmall {
    display: flex;
    flex-direction: column;

    align-items: center;
    vertical-align: center;
    margin: auto;

    padding: 2rem;
    background: var(--color-bg);
    max-width: 300px;
    height: 400px;

        /* ff 3.6+ */
        background:-moz-radial-gradient(ellipse at 100% 100%, rgba(8, 139, 150, 1) 0%, rgba(4, 12, 24, 1) 100%);

        /* safari 5.1+,chrome 10+ */
        background:-webkit-radial-gradient(ellipse at 100% 100%, rgba(8, 139, 150, 1) 0%, rgba(4, 12, 24, 1) 100%);
    
        /* opera 11.10+ */
        background:-o-radial-gradient(ellipse at 100% 100%, rgba(8, 139, 150, 1) 0%, rgba(4, 12, 24, 1) 100%);
    
        /* ie 10+ */
        background:-ms-radial-gradient(ellipse at 100% 100%, rgba(8, 139, 150, 1) 0%, rgba(4, 12, 24, 1) 100%);
    
        /* global 92%+ browsers support */
        background:radial-gradient(ellipse at 100% 100%, rgba(8, 139, 150, 1) 0%, rgba(4, 12, 24, 1) 100%);
}

.portfolio__projectSmall-title {
    font-family: var(--font-family);
    text-align: center;
    margin-top: auto;
    margin-bottom: 2rem;
}

.portfolio__projectSmall-title div {
    width: 80px;
    height: 3px;

    background: var(--gradient-bar);
    box-shadow: 0 4px 4px rgba(0,0,0,0.5);
    margin-top: 0.25rem;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}


.portfolio__projectSmall-text {
    font-family: var(--font-family);
    color: var(--color-text);
    text-align: center;
    margin-bottom: 2rem;
}

.portfolio__projectSmall-tags {
    display: flex;
    margin-bottom: auto;
}