@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

:root {
    --font-family: 'Roboto', sans-serif;

    --gradient-text: linear-gradient(89deg, #00FFFF, #CBC3E3);
    --gradient-bar: linear-gradient(89deg, #00FFFF, #CBC3E3);

    --color-bg: #040C18;
    --color-footer: #582e58;
    --color-project: #e7e7e7;
    --color-text: #FFFFFF;
    --color-text-dark: #000000;
    --color-subtext: #BF40BF;

}