.portfolio__about {
    display: flex;
    flex-direction: column;

    padding: 2rem;
    background: var(--color-bg);
 
    /* Generated here: https://angrytools.com/gradient/ */
    /* ff 3.6+ */
    background:-moz-radial-gradient(ellipse at 100% 100%, rgba(5, 120, 111, 1) 2%, rgba(2, 63, 62, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(ellipse at 100% 100%, rgba(5, 120, 111, 1) 2%, rgba(2, 63, 62, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(ellipse at 100% 100%, rgba(5, 120, 111, 1) 2%, rgba(2, 63, 62, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(ellipse at 100% 100%, rgba(5, 120, 111, 1) 2%, rgba(2, 63, 62, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(ellipse at 100% 100%, rgba(5, 120, 111, 1) 2%, rgba(2, 63, 62, 1) 100%);
}

.portfolio__about-feature {
    display: flex;
}

.portfolio__about-feature .portfolio__about-container__feature{
    margin: 0;
}

.portfolio__about-feature .portfolio__features-container__feature-text {
    max-width: 700px;
}

.portfolio__features-container_feature-image img {
    width: 100px;
    height: 100px;
}

.portfolio__about-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: 0 0 2rem;
}

.portfolio__about-heading h1 {
    font-size: 34px;
    font-family: var(--font-family);
    line-height: 45px;
    font-weight: 800;
    max-width: 510px;
}

.portfolio__about-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    margin-top: 2rem;
}

.portfolio__about-container .portfolio__features-container__feature {
    flex: 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
}

.portfolio__about-container .portfolio__features-container__feature-text {
    margin-top: 0.5rem;
}

.portfolio__about {
    margin: 4rem 6rem;
}


@media screen and (max-width: 850px) {
    .portfolio__about-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .portfolio__about {
        margin: 4rem 6rem;
    }

}

@media screen and (max-width: 650px) {
    .portfolio__about-feature .portfolio__features-container__feature-text {
        margin-top: 0.5rem;
    }

    .portfolio__about-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }

    .portfolio__about {
        margin: 4rem 6rem;
    }

}

@media screen and (max-width: 460px) {
    .portfolio__about-container .portfolio__features-container__feature {
        margin: 1rem 0;
        min-width: 200px;
    }

    .portfolio__about {
        margin: 1rem 1rem;
    }
}