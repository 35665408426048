.portfolio__projects {
    display: flex;
    flex-direction: column;

    padding: 2rem;
    margin-bottom: auto;
    background: var(--color-bg);
 
    /* Generated here: https://angrytools.com/gradient/ */
    /* ff 3.6+ */
    background:-moz-radial-gradient(ellipse at 100% 100%, rgba(5, 120, 111, 1) 2%, rgba(2, 63, 62, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(ellipse at 100% 100%, rgba(5, 120, 111, 1) 2%, rgba(2, 63, 62, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(ellipse at 100% 100%, rgba(5, 120, 111, 1) 2%, rgba(2, 63, 62, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(ellipse at 100% 100%, rgba(5, 120, 111, 1) 2%, rgba(2, 63, 62, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(ellipse at 100% 100%, rgba(5, 120, 111, 1) 2%, rgba(2, 63, 62, 1) 100%);
}

.portfolio__projects {
    margin: 4rem 6rem;
}

@media screen and (max-width: 460px) {

    .portfolio__projects {
        margin: 1rem 1rem;
    }
}