* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-bg) /* css variable for background color */
}

a {
    color: unset;
    text-decoration: none;
}

.gradient__bg {
    /* Generated here: https://angrytools.com/gradient/ */

        /* ff 3.6+ */
        background:-moz-linear-gradient(142deg, rgba(8, 139, 150, 1) 1%, rgba(20, 61, 66, 1) 36%, rgba(107, 58, 107, 1) 99%); 
    
        /* safari 5.1+,chrome 10+ */
        background:-webkit-linear-gradient(142deg, rgba(8, 139, 150, 1) 1%, rgba(20, 61, 66, 1) 36%, rgba(107, 58, 107, 1) 99%);
    
        /* opera 11.10+ */
        background:-o-linear-gradient(142deg, rgba(8, 139, 150, 1) 1%, rgba(20, 61, 66, 1) 36%, rgba(107, 58, 107, 1) 99%);
    
        /* ie 6-9 */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6B3A6B', endColorstr='#088B96', GradientType=1 );
    
        /* ie 10+ */
        background:-ms-linear-gradient(142deg, rgba(8, 139, 150, 1) 1%, rgba(20, 61, 66, 1) 36%, rgba(107, 58, 107, 1) 99%);
    
        /* global 94%+ browsers support */
        background:linear-gradient(142deg, rgba(8, 139, 150, 1) 1%, rgba(20, 61, 66, 1) 36%, rgba(107, 58, 107, 1) 99%);

}

.gradient__text {
    background:var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section__padding {
    padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

/* CSS Animations Generated With: https://animista.net/ */
.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-7-9 13:23:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }


/* Sizing for different devices */
/* Tablets */
@media screen and(max-width: 700px) {
  .section__padding {
      padding: 4rem;
  }
  .section__margin {
      margin: 4rem;
  }
}
/* Phones */
@media screen and(max-width: 550px) {
  .section__padding {
      padding: 4rem 2rem;
  }
  .section__margin {
    margin: 0rem;
}
}

/* Phones */
@media screen and(max-width: 450px) {
  .section__padding {
      padding: 4rem 2rem;
  }
  .section__margin {
      margin: 1rem 1rem;
  }
}