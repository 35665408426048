.portfolio__header {
    display: flex;
}

.portfolio__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.portfolio__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;

    font-size: 60px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.portfolio__header-content p {
    font-family: var(--font-family);

    font-size: 20px;
    line-height: 30px;

    color: var(--color-text);

    margin-top: 1.5rem;
}

@media screen and (max-width: 750px){
    .portfolio__header-image {
        display: none;
    }
}
