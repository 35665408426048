.portfolio__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    padding: 2rem;
    
    background: var(--color-bg);
}

.portfolio__brand div {
    flex: 1;
    max-width: 350px;
    min-width: 120px;
    margin: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.portfolio__brand-GitHub {
    width: 100px;
}

.portfolio__brand-LinkedIn {
    width: 35px;
    height: 35px;
}